import React, { useMemo } from 'react';
import { defaultAvatarText } from '@natomalabs/common/strings';
import type { User } from '../../__generated__/graphql';
import type { AvatarProps } from '@mui/material';
import { Avatar, Box } from '@mui/material';
import type { TypographyProps } from './Typography';
import { Body2, H4, Helper1 } from './Typography';

/** Additional props for UserAvatar */
interface UserAvatarProps {
  /** User object to render */
  user: User;
  /** Size to dictate custom size of Avatar */
  spacing?: number;
  /** If set to true, then only the Avatar without fullname text */
  avatarOnly?: boolean;
  /** If set to true, then displayName above email, if possible. Needs TextComponent defined */
  stacked?: boolean;
  /** If set to true, text is gray */
  grey?: boolean;
  /** If set to true, text has line-through */
  lineThrough?: boolean;
  /** TextComponent wraps the fullname text */
  TextComponent?: React.ElementType<TypographyProps>;
}

/** Props for the StackedName function */
interface StackedNameProps {
  user: User;
  TextComponent: React.ElementType<TypographyProps>;
}

/** Stacks displayName above email, if possible */
function StackedName({ user, TextComponent }: StackedNameProps) {
  return (
    <Box flex={1}>
      {user?.displayName ? (
        <>
          <TextComponent sx={{ fontWeight: 500 }}>
            {user?.displayName}
          </TextComponent>
          <TextComponent
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          >
            {user.email}
          </TextComponent>
        </>
      ) : (
        <TextComponent sx={{ fontWeight: 500 }}>{user.email}</TextComponent>
      )}
    </Box>
  );
}

/** A single User Avatar, with optional display for the name itself */
export default function UserAvatar({
  user,
  spacing = 2,
  avatarOnly = false,
  stacked = false,
  grey = false,
  lineThrough = false,
  TextComponent,
  ...props
}: UserAvatarProps & AvatarProps) {
  /** Fullname value */
  const nameValue = useMemo(
    () => (user.displayName ? user.displayName : user.email),
    [user?.displayName, user?.email]
  );
  /** avatar value depending on displayName or email */
  const avatarValue = useMemo(() => defaultAvatarText(nameValue), [nameValue]);

  return avatarOnly ? (
    <Avatar
      sx={(theme) => ({
        width: theme.spacing(spacing),
        height: theme.spacing(spacing),
        textDecoration: 'none'
      })}
      {...props}
    >
      <Helper1>{avatarValue}</Helper1>
    </Avatar>
  ) : (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        gap: 1,
        overflowX: 'hidden'
      }}
    >
      <Avatar
        sx={(theme) => ({
          position: 'static',
          width: theme.spacing(spacing),
          height: theme.spacing(spacing),
          textDecoration: 'none'
        })}
        {...props}
      >
        {spacing >= 5 ? (
          <H4>{avatarValue}</H4>
        ) : spacing >= 3 ? (
          <Body2>{avatarValue}</Body2>
        ) : (
          <Helper1>{avatarValue}</Helper1>
        )}
      </Avatar>
      <Box
        sx={(theme) => ({
          flexGrow: 1, // Allow the text to take up remaining space
          ...(grey && { color: theme.palette.text.secondary }),
          ...(lineThrough && { textDecoration: 'line-through' }),
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          '& *': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }
        })}
      >
        {TextComponent ? (
          stacked ? (
            <StackedName user={user} TextComponent={TextComponent} />
          ) : (
            <TextComponent>{nameValue}</TextComponent>
          )
        ) : (
          nameValue
        )}
      </Box>
    </Box>
  );
}
