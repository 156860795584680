import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link
} from '@mui/material';
import type { ReactElement, ReactNode } from 'react';
import React, { useContext, useMemo } from 'react';
import { H2, Subtitle1 } from './Typography';
import { usePathname } from 'next/navigation';
import { MeContext } from '../providers/Me';
import ProfileButton from './ProfileButton';
import OrgSwitcher from './OrgSwitcher';
import {
  PuzzlePieceIcon,
  FingerprintIcon,
  SettingsIcon,
  AlertSquareIcon,
  UserSquareIcon,
  CursorBoxIcon,
  AuditLogIcon,
  DashboardIcon,
  AnnouncementIcon
} from '@/src/icons';
import useIsFeatureSwitchEnabled from '../hooks/use_feature_switch';
import type { FeatureSwitchKey } from '@natomalabs/common/feature_switches';

interface ISidebarItem {
  id: string;
  label: string;
  href: string;
  // If hidden is set to true, the disabled state will be ignored
  disabled: boolean;
  hidden?: boolean;
  // Recursively nested sidebar tabs
  nested?: ISidebarItem[];
  icon: ReactElement;
  featureSwitch?: FeatureSwitchKey;
}

const SidebarItems: ISidebarItem[] = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    href: '/dashboard',
    disabled: false,
    hidden: true,
    icon: <DashboardIcon size="large" />,
    featureSwitch: 'showDashboardView'
  },
  {
    id: 'directory',
    label: 'Directory',
    href: '/directory',
    disabled: false,
    icon: <FingerprintIcon size="large" />,
    nested: [
      {
        id: 'directory-accounts',
        label: 'Accounts',
        href: '/directory',
        disabled: false,
        icon: <UserSquareIcon size="medium" />
      },
      {
        id: 'directory-entitlements',
        label: 'Entitlements',
        href: '/entitlements',
        disabled: true,
        icon: <CursorBoxIcon size="medium" />,
        featureSwitch: 'displayEntitlementTableAndDetail'
      }
    ]
  },
  {
    id: 'campaigns',
    label: 'Campaigns',
    href: '/campaigns',
    disabled: true,
    icon: <AnnouncementIcon size="large" />,
    featureSwitch: 'showCampaignsForAdmins'
  },
  {
    id: 'issues',
    label: 'Issues',
    href: '/issues',
    disabled: true,
    icon: <AlertSquareIcon size="large" />,
    featureSwitch: 'displayAlerts'
  },
  {
    id: 'integrations',
    label: 'Integrations',
    href: '/integrations',
    disabled: false,
    icon: <PuzzlePieceIcon size="large" />
  },
  {
    id: 'audit-logs',
    label: 'Audit logs',
    href: '/auditlogs',
    disabled: false,
    icon: <AuditLogIcon size="large" />,
    featureSwitch: 'showAuditLogEvents'
  },
  {
    id: 'settings',
    label: 'Settings',
    href: '/settings/general',
    disabled: false,
    icon: <SettingsIcon size="large" />
  }
];

/**
 * Helper component for one sidebar button - configurations are defined in `SidebarItems`.
 * Renders recursively if the item is nested.
 *
 * @param item SideBarItem metadata to render
 * @returns React.JSX.Element that represents one sidebar button
 */
const SidebarLayoutButton = ({
  item,
  showNested,
  isNested
}: {
  item: ISidebarItem;
  showNested: boolean;
  isNested?: boolean;
}) => {
  const isFeatureSwitchEnabled = useIsFeatureSwitchEnabled();
  const pathname = usePathname();

  // determine if this button is disabled
  const disabled = useMemo(() => {
    return item.featureSwitch
      ? !isFeatureSwitchEnabled(item.featureSwitch)
      : item.disabled;
  }, [item, isFeatureSwitchEnabled]);

  // determine if this is an active button
  const isActive = useMemo(() => {
    const pathValue = `/${pathname.split('/')[1]}`;
    return (
      item.href.startsWith(pathValue) ||
      (item.nested && item.nested.some((v) => v.href.startsWith(pathValue)))
    );
  }, [item, pathname]);

  return (
    <>
      <ListItemButton
        component={Link}
        disabled={disabled}
        key={item.id}
        href={item.href}
        data-active={isActive}
        sx={(theme) => ({
          '&.MuiListItemButton-root': {
            m: 0,
            ...(isNested && { borderRadius: theme.spacing(1.5) })
          },
          columnGap: 1.5,
          '&[data-active="true"]': {
            backgroundColor: theme.palette.primary.main
          },
          ...(isNested && { height: '32px' })
        })}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.label} />
        {disabled && <Subtitle1 fontStyle={'italic'}>Coming Soon</Subtitle1>}
      </ListItemButton>
      {showNested && item.nested && item.nested.length > 0 && (
        <List
          data-active={isActive}
          disablePadding
          component="div"
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            ml: 3,
            pl: 1,
            borderLeft: '2px solid',
            borderColor: theme.palette.grey[200],
            '&[data-active="true"]': {
              borderColor: theme.palette.primary.main
            }
          })}
        >
          {item.nested.map((nestedItem) => {
            const hidden = nestedItem.featureSwitch
              ? !isFeatureSwitchEnabled(nestedItem.featureSwitch)
              : nestedItem.hidden;
            return (
              !hidden && (
                <SidebarLayoutButton
                  key={nestedItem.id}
                  item={nestedItem}
                  showNested={showNested}
                  isNested
                />
              )
            );
          })}
        </List>
      )}
    </>
  );
};

export default function SidebarLayout({ children }: { children: ReactNode }) {
  const { isAuthorized } = useContext(MeContext);
  const isFeatureSwitchEnabled = useIsFeatureSwitchEnabled();
  // determine if we should show the nested buttons
  const showNested = useMemo(() => {
    return isFeatureSwitchEnabled('displaySidebarLayoutNested');
  }, [isFeatureSwitchEnabled]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '100%',
        minHeight: '100%',
        backgroundColor: theme.palette.grey['100']
      })}
    >
      {/* sidebar */}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          minWidth: '220px',
          maxWidth: '220px',
          margin: theme.spacing(1.5),
          borderRadius: theme.spacing(2),
          justifyContent: 'space-between'
        })}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 2.5 }}>
          <H2
            sx={(theme) => ({
              fontWeight: 700,
              color: theme.palette.primary.main,
              textAlign: 'center'
            })}
          >
            <OrgSwitcher />
          </H2>
          {isAuthorized && (
            <Box
              sx={(theme) => ({
                borderRadius: theme.spacing(0.5),
                paddingBottom: theme.spacing(2)
              })}
            >
              <List
                sx={(theme) => ({
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  width: '100%',
                  maxWidth: 360,
                  color: theme.palette.grey['900'],
                  padding: theme.spacing(0),
                  '& .MuiListItemButton-root': {
                    borderRadius: theme.spacing(2),
                    margin: '1px'
                  },
                  '& .MuiListItemButton-root:hover': {
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.grey['900']
                  },
                  '& .MuiListItemIcon-root': {
                    minWidth: theme.spacing(0),
                    color: theme.palette.grey['900']
                  }
                })}
                component="nav"
              >
                {SidebarItems.map((item) => {
                  const hidden = item.featureSwitch
                    ? !isFeatureSwitchEnabled(item.featureSwitch)
                    : item.hidden;
                  return (
                    !hidden && (
                      <SidebarLayoutButton
                        key={item.id}
                        item={item}
                        showNested={showNested}
                      />
                    )
                  );
                })}
              </List>
            </Box>
          )}
        </Box>
        <Box>
          <ProfileButton />
        </Box>
      </Box>
      {/* main content */}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          [`@media (max-width: 1000px)`]: {
            overflow: 'auto'
          }
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100vh',
            maxHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={(theme) => ({
              flexGrow: 1,
              minWidth: '1000px',
              margin: theme.spacing(1),
              [theme.breakpoints.up('xl')]: {
                padding: theme.spacing(3),
                maxHeight: `calc(100% - ${theme.spacing(2)})`
              },
              [theme.breakpoints.down('xl')]: {
                padding: theme.spacing(2),
                maxHeight: `calc(100% - ${theme.spacing(2)})`
              },
              bgcolor: theme.palette.common.white,
              borderRadius: theme.spacing(2)
            })}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
