import * as React from 'react';
import { Box, Button, Link } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { Body2, H5 } from './Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BuildingIcon, CheckIcon, ChevronDownIcon, NatomaIcon } from '../icons';
import { usePathname } from 'next/navigation';
import { MeContext } from '../providers/Me';
import { useGetMyOrgsQuery } from '../../__generated__/graphql';
import { orgUrl } from '../lib/paths';
import useEnvironment from '../providers/Environment';

export default function OrgSwitcher() {
  const path = usePathname();
  const { natoma } = useEnvironment();
  const { currentOrg: currentOrgData } = useContext(MeContext);
  const { data } = useGetMyOrgsQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentOrgName, setCurrentOrgName] = useState<string | undefined>(
    undefined
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentOrgData) {
      setCurrentOrgName(currentOrgData.displayName!);
    }
  }, [currentOrgData]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button
        id="postioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        onClick={handleClick}
        fullWidth
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          px: theme.spacing(1.5),
          py: theme.spacing(1),
          borderRadius: theme.spacing(2),
          justifyContent: 'start',
          '&:hover': {
            backgroundColor: theme.palette.grey[200]
          }
        })}
      >
        <NatomaIcon width={28} height={28} />
        <H5
          sx={(theme) => ({
            textOverflow: 'ellipsis',
            color: theme.palette.grey[900],
            flex: 1,
            textAlign: 'start'
          })}
          noWrap
        >
          {currentOrgName ?? 'No Org Provided'}
        </H5>
        <ChevronDownIcon />
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: (theme) => ({
              p: theme.spacing(1),
              width: anchorEl && anchorEl.offsetWidth
            })
          }
        }}
      >
        {data?.myOrgs.map((org, i) => (
          <MenuItem
            key={i}
            sx={(theme) => ({ gap: theme.spacing(1.25) })}
            onClick={handleClose}
            disabled={currentOrgName === org.displayName}
            component={Link}
            href={`${orgUrl(org.slug, natoma.rootDomain)}${path}`}
          >
            <BuildingIcon />
            <Body2
              sx={{
                flex: 1,
                textOverflow: 'ellipsis'
              }}
              noWrap
            >
              {org.displayName}
            </Body2>
            {currentOrgName === org.displayName && <CheckIcon />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
