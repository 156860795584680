export function truncateList(items: string[], limit: number = 1) {
  const front = items.slice(0, limit);
  const back = items.slice(limit);

  if (back.length === 0) {
    if (front.length === 1) {
      return front[0];
    }
    const last = front.pop();
    return [front.join(', '), last].join(' and ');
  }
  return `${front.join(', ')} and ${back.length} more`;
}

/** Email validator, not quite RFC 5322 - would be overkill */
export function validateEmail(email: string) {
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  // return some descriptive error
  if (!email || email.length > 254 || !emailRegex.test(email)) {
    return { isValid: false, message: 'Invalid email format' };
  }

  const [localPart, domain] = email.split('@');
  if (
    localPart.length > 64 ||
    domain.split('.').some((part) => part.length > 63)
  ) {
    return { isValid: false, message: 'Email length too long' };
  }

  return { isValid: true, message: 'Email is valid' };
}

/** Converts snake case into a title case */
export function convertSnakeToTitle(value: string) {
  return value
    .toLowerCase() // handle screaming
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');
}

/* Set default avatar text */
export function defaultAvatarText(name: string | null | undefined) {
  if (name) {
    const nameSplit = name.split(' ');
    if (nameSplit.length === 1) {
      return nameSplit[0].slice(0, 2).toUpperCase();
    } else {
      return `${nameSplit[0][0].toUpperCase()}${nameSplit[nameSplit.length - 1][0].toUpperCase()}`;
    }
  } else {
    return '';
  }
}
