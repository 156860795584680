'use client';

import SidebarLayout from '@/src/components/SidebarLayout';
import type { ReactNode } from 'react';

export default function SidebarContentLayout({
  children
}: {
  children: ReactNode;
}) {
  return <SidebarLayout>{children}</SidebarLayout>;
}
