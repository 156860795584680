import type { FeatureSwitchKey } from '@natomalabs/common/feature_switches';
import { useContext } from 'react';
import useEnvironment from '../providers/Environment';
import { MeContext } from '../providers/Me';

export default function useIsFeatureSwitchEnabled() {
  const { currentOrg } = useContext(MeContext);
  const { featureSwitches } = useEnvironment();

  return (feature: FeatureSwitchKey) => {
    const overrideValue = currentOrg?.featureSwitchOverrides?.[feature];
    return overrideValue ?? featureSwitches[feature];
  };
}
