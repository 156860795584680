import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { H5 } from './Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LogOutIcon } from '@/src/icons';
import { useRouter } from 'next/navigation';
import { LogoutPath } from '../lib/paths';
import { MeContext } from '../providers/Me';
import UserAvatar from './UserAvatar';
import type { User } from '../../__generated__/graphql';

export default function ProfileButton() {
  const router = useRouter();

  const { currentUser: currentUserData } = useContext(MeContext);
  const [currentUserName, setCurrentUserName] = useState<string | undefined>(
    undefined
  );
  const [currentUserEmail, setCurrentUserEmail] = useState<string | undefined>(
    undefined
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (currentUserData) {
      setCurrentUserEmail(currentUserData.email);
      if (currentUserData.displayName) {
        setCurrentUserName(currentUserData.displayName);
      }
    }
  }, [currentUserData]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Button
        id="postioned-button"
        aria-controls={open ? 'positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        onClick={handleClick}
        fullWidth
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(1),
          px: theme.spacing(1.5),
          py: theme.spacing(1),
          borderRadius: theme.spacing(2),
          justifyContent: 'start'
        })}
      >
        {(currentUserEmail || currentUserName) && (
          <UserAvatar
            user={currentUserData as User}
            spacing={3.5}
            TextComponent={H5}
          />
        )}
      </Button>
      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        slotProps={{
          paper: {
            sx: (theme) => ({
              p: theme.spacing(1),
              width: anchorEl && anchorEl.offsetWidth
            })
          }
        }}
        sx={(theme) => ({
          mt: theme.spacing(-0.5)
        })}
      >
        <MenuItem
          sx={(theme) => ({
            gap: theme.spacing(1.25)
          })}
          onClick={() => {
            handleClose();
            router.push(LogoutPath);
          }}
        >
          <LogOutIcon />
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
}
